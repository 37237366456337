import { createContext, ReactElement, useState } from "react";
import { languagesT } from "src/utilits/interfaces";

interface ILanguageContext {
  language: languagesT;
  setLanguage: (language: languagesT) => void;
}




export const LanguageContext = createContext<ILanguageContext>({
  language: "en",
  setLanguage: () => {},
});


const LanguageProvider = ({children}: { children: ReactElement }) => {
  const [language, setLanguage] = useState<languagesT>("en")
  return (
    <LanguageContext.Provider value={{language,setLanguage}}>{children}</LanguageContext.Provider>
  )
}

export default LanguageProvider

