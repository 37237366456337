import { useTranslation } from "react-i18next";
import style from "./style.module.css";
import data from "src/utilits/data.json";
import aboutUSImg from "src/assets/images/1.jpg";
import { RouteNames } from "src/router";
import { NavLink } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  return (
    <main className={style.homePage}>
      <section className={style.aboutUs}>
        <div className={style.container + " " + "G-container"}>
          <img src={aboutUSImg} alt="construction" />
          <div className={style.aboutUsText}>
            <h3>{t("Cross Construction Development Company")}</h3>
            <p>{t("Cross Construction is")}</p>

            <NavLink to={RouteNames.about_us}>{t("Read more")}</NavLink>
          </div>
        </div>
      </section>
      <section className={style.gallery}>
        <div className={style.container + " " + "G-container"}>
          {data.map((item) => (
            <NavLink to={`?tab=${item._id}`} key={item._id}>
            <img src={item.mainImage} alt="construction" />
            </NavLink>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Home;
