import { useContext, useEffect, useState } from "react";
import style from "./style.module.css";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { ISelected } from "src/utilits/interfaces";

import flagAm from "src/assets/images/flags/Flag_of_Armenia.webp";
import flagEn from "src/assets/images/flags/Flag_of_the_United_Kingdom.webp";
import flagRu from "src/assets/images/flags/Flag_of_Russia.webp";
import ClickOutside from "../clickOutside";
import { LanguageContext } from "src/context/languageContext";

const Row = ({
  flagSrc,
  langaugeCode,
}: {
  langaugeCode: string | undefined;
  flagSrc: string | undefined;
}) => {
  return (
    <div className={style.language_switcher_row}>
      <img src={flagSrc} alt="flag" className={style.flag} width={30} height={20}/>
      <span className={style.country_name}>{langaugeCode}</span>
    </div>
  );
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const {setLanguage}=useContext(LanguageContext)
  const options: ISelected[] = [
    { title: "ARM", icon: flagAm, id: "hy" },
    { title: "ENG", icon: flagEn, id: "en" },
    { title: "RUS", icon: flagRu, id: "ru" },
  ];
  const [active, setActive] = useState<boolean>(false);
  const [selected, setSelected] = useState<ISelected | undefined>();
  const [country, setCountry] = useState<{
    name: string;
    language: string;
  }>({
    language: "",
    name: "",
  });
  const languageChanger = (item: ISelected) => {
    setLanguage(item.id)
    setSelected(item);
    localStorage.setItem("current_language", item.id);
    document.documentElement.setAttribute("lang", selected?.id as string);
  };
  const getGeoInfo = async () => {
    try {
      const result: AxiosResponse = await axios.get("https://ipapi.co/json/");
      let data = result.data;
      setCountry({
        name: data.country_name,
        language: data.languages,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  useEffect(() => {
    if (country.language) {
      const currentLocalLanguage = options.find(
        (item) => item.id === country.language
      );
      let currentLanguage: ISelected = {
        title: "EN",
        icon: flagEn,
        id: "en",
      };
      if (
        !!localStorage.getItem("current_language") &&
        !!options.find(
          (item) => item.id === localStorage.getItem("current_language")
        )
      ) {
        currentLanguage = options.find(
          (item) => item.id === localStorage.getItem("current_language")
        ) as ISelected;
      } else if (!!currentLocalLanguage) {
        currentLanguage = currentLocalLanguage;
      }

      languageChanger(currentLanguage);
    }
  }, [country.language]);
  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(selected?.id);
      document.documentElement.setAttribute("lang", selected?.id as string);
    })();
  }, [selected?.id]);
  return (
    <ClickOutside onClickOutside={() => setActive(false)}>
      <div className={style.languageSwitcher}>
        <div
          className={style.language_switcher_head}
          onClick={() => setActive(!active)}
        >
          <Row flagSrc={selected?.icon} langaugeCode={selected?.title} />
          <span
            className={active ? "icon-arrow_drop_up" : "icon-arrow_drop_down"}
          />
        </div>

        <ul
          className={`${style.language_switcher_list} ${
            active ? style.language_switcher_list_active : ""
          }`}
        >
          {options.map((item) => {
            if (item.id !== selected?.id) {
              return (
                <li key={item.id} onClick={() => languageChanger(item)}>
                  <Row flagSrc={item.icon} langaugeCode={item.title} />
                </li>
              );
            }
          })}
        </ul>
      </div>
    </ClickOutside>
  );
};

export default LanguageSwitcher;
