import AboutUs from "./about-us"
import ContactUs from "./contact-us"
import Gallery from "./gallery"
import Home from "./home"


export default{
    Home,
    AboutUs,
    ContactUs,
    Gallery
}