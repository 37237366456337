import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import style from "./style.module.css";
import logo from "src/assets/images/logo.webp";
import data from "src/utilits/data.json";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../languageSwitcher";
import { navList } from "src/router";
import { Fragment, useContext, useEffect, useState } from "react";
import ClickOutside from "../clickOutside";
import PopUp from "src/pages/gallery/components/popUp";
import { LanguageContext } from "src/context/languageContext";
import ClickOutsideBlock from "../clickOutsideBlock";
const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const [open,setOpen]= useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [openedPopUp, setOpenedPopUp] = useState<{
    images: string[];
    title: string;
    describtion: string;
  } | null>(null);

  const onClose = () => {
    searchParams.delete("tab");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const finded = searchParams.has("tab")
      ? data.find((item) => item._id === searchParams.get("tab"))
      : null;

    if (finded) {
      setOpenedPopUp({
        images: finded?.images,
        title: finded?.title[language],
        describtion: finded?.description[language],
      });
    } else {
      setOpenedPopUp(null);
      onClose();
    }
  }, [searchParams]);

  return (
    <header className={open?style.header +" " + style.active:style.header}>
      <div className={`G-container ${style.headerContainer}`}>
        <NavLink to="/" className={open?style.logo +" " + style.active:style.logo} onClick={()=>{setOpen(false)}}>
          <img src={logo} alt="logo" width={200} />
        </NavLink>
        <div className={open?style.menuIcon +" " + style.active:style.menuIcon} onClick={()=>{setOpen(!open)}}>
          <span className={style.row}></span>
          <span className={style.row}></span>
          <span className={style.row}></span>
        </div>
        <div className={open?style.menu +" " + style.active:style.menu}>
          <nav className={style.main_navigation}>
            {navList.map((item) => (
              <Fragment key={item.title}>
                {" "}
                <NavLink
                  to={item.path as string}
                  className={`${style.navigation_item}  ${
                    location.pathname === item.path ? style.active : ""
                  }`}
                  onClick={()=>setOpen(false)}
                >
                  {t(item.title)}
                </NavLink>
              </Fragment>
            ))}
          </nav>
          <LanguageSwitcher />
        </div>
      </div>
      {openedPopUp && (
        <ClickOutsideBlock onClickOutside={onClose}>
          <PopUp {...openedPopUp} />
        </ClickOutsideBlock>
      )}
    </header>
  );
};

export default Header;
