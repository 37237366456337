import React, { useContext, useEffect, useState } from "react";
import data from "src/utilits/data.json";
import Card from "./components/card";
import style from "./style.module.css";
import { LanguageContext } from "src/context/languageContext";
import { useSearchParams } from "react-router-dom";
import ClickOutside from "src/components/clickOutside";
import PopUp from "./components/popUp";
import { tab } from "@testing-library/user-event/dist/tab";

const Gallery = () => {
  const { language } = useContext(LanguageContext);

  return (
    <main className={"G-container"}>
      <div className={style.gallery}>
        {data.map((item) => (
          <React.Fragment key={item._id}>
            <Card
              src={item.mainImage}
              title={item.title[language]}
              describtion={item.description[language]}
              id={item._id}
            />
          </React.Fragment>
        ))}
      </div>
    </main>
  );
};

export default Gallery;
