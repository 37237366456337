import { NavLink, useNavigate } from "react-router-dom";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

const Card = ({
  src,
  title,
  describtion,
  id,
}: {
  src: string;
  title: string;
  describtion: string;
  id: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <div className={style.cardBox}>
        <div className={style.card} onClick={()=>navigate(`?tab=${id}`)}>
          <img src={src} alt="main_image" />
          <h3>{title}</h3>
          <p>{describtion}</p>
          <NavLink to={`?tab=${id}`}>{t("Read more")}</NavLink>
        </div>
    </div>
  );
};

export default Card;
