import React from "react";
import pages from "src/pages";

export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  title?: string;
  isNavLink?: boolean;
}

export interface IMenu {
  title: string;
  icon?: string;
  path?: string;
  color?: string;
  subList?: IMenu[];
}

export enum RouteNames {
  home = "/",
  about_us = "/about_us",
  contact_us = "/contact_us",
  gallery = "/gallery",
}

export const pagesRouteList: IRoute[] = [
  { path: RouteNames.home, component: pages.Home, exact: true },
  { path: RouteNames.about_us, component: pages.AboutUs, exact: true },
  { path: RouteNames.contact_us, component: pages.ContactUs, exact: true },
  { path: RouteNames.gallery, component: pages.Gallery, exact: true },
];
export const navList: IMenu[] = [
  {
    title: "about-us-text",
    path: RouteNames.about_us,
  },
  {
    title: "gallery-text",
    path: RouteNames.gallery,
  },
  {
    title: "contacts-text",
    path: RouteNames.contact_us,
  },
];
