
import Header from './components/header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { pagesRouteList } from './router';
import Footer from './components/footer';
import LanguageProvider from './context/languageContext';

function App() {
  return (
    <LanguageProvider>
    <BrowserRouter>
    <div className="App">
      <Header/>
      <Routes>
      {pagesRouteList.map(item=>(
        <Route path={item.path} element={<item.component/>} key={item.path}/>
      ))}
      </Routes>
      <Footer/>
    </div>
    </BrowserRouter>
    </LanguageProvider>
  );
  
}

export default App;
