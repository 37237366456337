import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';



import eng_translation from "./locales/en/translation.json";
import arm_translation from "./locales/hy/translation.json";
import rus_translation from "./locales/ru/translation.json";


const DEFAULT_LOCALE: string = "en";

i18n.use(initReactI18next).init({
    // debug: global.isDev,
    debug: false,
    resources: {
        en:{translation: eng_translation},
        hy:{translation: arm_translation},
        ru:{translation: rus_translation},
    
    },
    lng: DEFAULT_LOCALE,
    fallbackLng: "en",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
