import videoSrc from "src/assets/images/videoplayback.mp4";
import style from "./styel.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
const AboutUs = () => {
  const videoRef=useRef<HTMLVideoElement>(null)
  const { t } = useTranslation();
useEffect(()=>{
  if(videoRef.current) videoRef.current.playbackRate=.5;
  
},[videoRef.current])


  return (
    <main className={style.mainContainer}>
      <video autoPlay muted loop id="myVideo" className={style.myVideo} ref={videoRef}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className={style.textConatiner}>
        <h3>{t("Cross Construction Development Company")}</h3>
        <h4>{t("Company Description")}:</h4>
        <p>{t("Cross Construction is")}</p>
        <h4>{t("Our Services")}:</h4>
        <ul>
          <li>{t("Construction Works")}</li>
          <li>{t("Renovation Works")}</li>
        </ul>
        <h4>{t("Our Team")}</h4>
        <p>{t("Our team consists")}</p>
        <h4>{t("Features")}</h4>
        <ul>
          <li>{t("Years of Experience")}</li>
          <li>{t("High Quality")}</li>
          <li>{t("Individual Approach")}</li>
        </ul>
        <p>{t("If you are looking")}</p>
      </div>
      
    </main>
  );
};


export default AboutUs;
