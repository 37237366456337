import "./style.css";
import ContactsArea from "./components/contacts-area";
import WebFooter from "./components/web-footer";

const Footer = () => {

     return (
        <div className="P-footer">
            <ContactsArea/>
            <WebFooter/>
        </div>
    );
};

export default Footer;
