import React from "react";
import style from "./style.module.css";
import Slider, { Settings } from "react-slick";

const PopUp = ({
  images,
  title,
  describtion,
}: {
  images: string[];
  title: string;
  describtion: string;
}) => {
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
  };
  return (
    <div className={style.popUp} onClick={(event) => event.stopPropagation()}>
      <div className={style.sliderSection}>
        <Slider {...settings}>
          {images.map((item) => (
            <img src={item} alt="image" key={item} />
          ))}
        </Slider>
      </div>
      <div className={style.infoSection}>
        <h3>{title}</h3>
        <p>{describtion}</p>{" "}
      </div>
    </div>
  );
};

export default PopUp;
