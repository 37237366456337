import React, {FC,  ReactElement, useEffect} from 'react';
import style from "./style.module.css"
interface IClickOutSide {
    onClickOutside(e?: MouseEvent): void;

    children: ReactElement
}

const ClickOutside: FC<IClickOutSide> = ({onClickOutside, children}) => {
    
    return (
        <div className={style.outside} onClick={(e)=>onClickOutside()}>{children}</div>
    )
}

export default ClickOutside;