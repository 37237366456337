import logo from "src/assets/images/logo.webp";
import logoOrigin from "src/assets/images/NEW-CODE-ORIGINS-line.png";

import {navList} from "src/router";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFooter from "../../useFooter";

const WebFooter = () => {
    const {t} = useTranslation();

    return (<div className="P-footer-nav P-web">
        <div className="G-container">
            <div className="P-footer-logo">
                <img src={logo} alt="logo" className="logo"/>
                {/* <div className="P-social-icons">
                    <p>{t("FOLLOW-text")}</p>
                    <div className="P-icons">
                        <img
                            src={fb}
                            alt="Facebook"
                            onClick={() => {
                                goTo("https://www.facebook.com/HawX.io");
                            }}
                        />
                        <img
                            src={linkdin}
                            alt="Linkdin"
                            onClick={() => {
                                goTo("https://www.linkedin.com/company/hawx/");
                            }}
                        />
                        <img
                            src={insta}
                            alt="Instagram"
                            onClick={() => {
                                goTo("https://www.instagram.com/hawx.io/");
                            }}
                        />
                    </div>
                </div> */}
                <span>
              {" "}
                    © {new Date().getFullYear()} {t("copyright")}{" "}
            </span>
            <span>{t("created-by-text")} <NavLink to={"https://codeorigins.am/"} target="_blank"><img src={logoOrigin} alt="CodeOrigns" className="logoCreator"/></NavLink></span>
            </div>
            <div className="P-footer-navigation">
                <div className="P-sections">
                    <h4>{t("Company-text")}</h4>
                    {navList.map((item, index) => {
                        return (
                            <NavLink to={item.path as string} key={item.title + index}>
                                {t(item.title)}
                            </NavLink>
                        );
                    })}
                </div>
              
            </div>
        </div>
    </div>)
}

export default WebFooter